import backArrow from 'assets/icons/arrow_back.svg';
import Check from 'assets/icons/check-background-green.svg';
import BagIcon from 'assets/icons/bag-csv.svg';
import CopyIcon from 'assets/icons/copy.png';
import SaveIcon from 'assets/icons/ok.svg';
import CSVIcon from 'assets/icons/csv-icon.svg';
import Revenues from 'assets/icons/revenues.svg';
import TextArea from 'components/Forms/TextArea/index';
import Toast from 'components/Toast';
import Text from 'components/Typografy/Text/index';
import moment from 'moment';
import ModalSendContact from 'pages/Customers/CreateCatalogModal';
import CatalogItemCard from 'pages/Customers/CreateCatalogModal/CatalogItemCard';
import MacroHelpModal from 'pages/Customers/MacroHelpModal';
import React, { useEffect, useState, memo } from 'react';
import { CSVLink } from "react-csv";
import { Row } from 'react-grid-system';
import { FaWhatsapp } from 'react-icons/fa';
import Loader from 'react-loader-spinner';
import { useParams, useHistory } from "react-router-dom";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  TelegramIcon,
} from "react-share";
import { getSpecificCatalog, patchSpecificCatalog, getInstaList, postCustomerReceived, SaveCatalogMessage } from 'services/catalog-services';
import smartSalesSessionStorage, { storageKeys } from "storage/smart-sales-session-storage";
import { StyledFlexContainer } from 'styles/global';
import theme from 'styles/theme';

import {
  StyledMainContent,
  StyledTitle,
  StyledSubtitle,
  StyledInput,
  StyledLabel,
  StyledButton,
  StyledForms,
  StyledCatalogList,
  StyledListItems,
  StyledLoadingContainer,
  StyledBody,
  StyledBox,
  StyledCard,
  StyledWhatsappButton,
  StyledContactSubtitle,
  StyledBackButton,
  StyledHeader,
  Footer,
  StyledRevenuesContainer,
  StyledRevenues,
  StyledCSV,
  StyledCSVDescription,
  StyledEditContainer,
  StyledCustomLink,
  StyledCustomIcon,
  StyledButtonCopy,
  StyledCheckIcon,
  StyledFooterButtons,
  StyledButtonModal
} from './styles';

function SpecificSellerCatalog() {
  const { id } = useParams();
  const [name, setName] = useState('');
  const [expiresAt, setExpiresAt] = useState(null);
  const [toastInfo, setToastInfo] = useState();
  const [defaultText, setDefaultText] = useState('*Olá*, [nome_usuario] sou [seller] %0a%0aSeparamos ofertas especiais pra você, da uma olhada nessa lista: %0a%0a[lista_produtos] Mas não esquece que as ofertas são por tempo limitado ou até acabarem nossos estoques. %0a%0aEntão corre lá e confere o link [link_catalogo]');
  const [text, setText] = useState();
  const [catalogUrl, setCatalogUrl] = useState('');
  const [catalogInfo, setCatalogInfo] = useState({});
  const [sendedUsers, setSendedUsers] = useState([]);
  const [copied, setCopied] = useState('');
  const [saved, setSaved] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { portalUrl } = smartSalesSessionStorage.getItem(storageKeys.STORE);
  const baseurl = window.location.origin;
  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [CSVProducts, setCSVProducts] = useState([]);
  const [associetedCampaign, setAssocietedCampaign] = useState({
    id: 1,
    name: 'Campanha_remarketing_40off_femJeans',
    description: 'Campanha de e-mail enviada para as clientes que compraram calça jeans entre dezembro de 2021 e fevereiro de 2022',
    promocode: 'MEUJEANSCOM4OFF'
  });
  const sellerId = smartSalesSessionStorage.getItem(storageKeys.SELLER);

  const getProductList = async () => {
    const response = await getInstaList(id);
    if (response?.data?.payload) {
      setCSVProducts(response?.data?.payload);
    }
  }

  useEffect(() => {
    getProductList();
  }, [id]);

  const history = useHistory();

  useEffect(() => {
    setCatalogUrl(`${baseurl}/${portalUrl}/catalogo/${id}${catalogInfo?.seller ? `?sellerId=${sellerId?.id}` : ''}`);
  }, [portalUrl, catalogInfo]);

  const setStates = (data) => {
    setName(data?.name);
    setCatalogInfo(data);

    if (data?.message === "" || data?.message === null || data?.message === undefined) {
      setText(defaultText);
    } else {
      setText(data?.message);
    }

    if (data?.expiresAt) {
      setExpiresAt(data?.expiresAt?.split('T')[0]);
    }

    setProducts(data?.products);
    setCustomers(data?.customers);
  };

  const getData = async () => {
    setIsLoading(true);
    const response = await getSpecificCatalog(id);
    setStates(response?.data?.payload);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [id]);

  const CloseModalSendContact = (status) => {
    setOpen(status)
    getData();
  }

  const CloseModalMacro = (status) => {
    setOpenModal(status);
  }

  const onFormSubmit = async (e) => {
    try {
      e.preventDefault();
      const body = {
        name,
        expiresAt: expiresAt || null,
        productsIds: products.map((product) => product.id),
        customersIds: customers.map((customer) => customer.id),
        sellerId: catalogInfo?.seller?.id
      }

      setIsSubmiting(true);
      const response = await patchSpecificCatalog(id, body);
      setToastInfo({ isError: false, message: response?.data.message });
      setIsSubmiting(false);
      setOpen(false);
    } catch (error) {
      setToastInfo({ isError: true, message: error?.message });
      setIsSubmiting(false);
    }
  }

  const sendMessage = async (customerId, customerName, telephone) => {
    setSendedUsers([...sendedUsers, customerId]);
    const listProducts = getListProductsString();
    const whatsappMessage = text.replace('[nome_usuario]', customerName).replace('[seller]', catalogInfo?.seller?.name).replace('[lista_produtos]', listProducts).replace('[link_catalogo]', catalogUrl);
    const uri = `https://wa.me/${telephone}?text=${whatsappMessage}`;

    window.open(uri, '_blank');

    await postCustomerReceived(id, customerId);
  }

  const getListProductsString = () => {
    let productsListString = '';
    const productsNames = catalogInfo.products.map(({ name, basePrice }) => ({ name, basePrice }));
    productsNames.forEach((element) => { productsListString += '• *' + element.name + "*  por apenas: *R$: " + element.basePrice + "* ," });
    productsListString = productsListString.split(",").join("%0a%0a")

    return productsListString;
  }

  const handleBack = () => {
    history.goBack()
  }

  const handleCopyMessage = () => {
    const listProducts = getListProductsString();
    const message = text
      .replace('[seller]', catalogInfo?.seller?.name)
      .replace('[lista_produtos]', listProducts)
      .replace('[link_catalogo]', catalogUrl);

    navigator.clipboard.writeText(message);
    setCopied(true)
    setTimeout(() => { setCopied(false) }, [2000]);
  }

  const handleSaveMessage = () => {
    const body = { message: text };
    SaveCatalogMessage(id, body);
    setSaved(true)
    setTimeout(() => { setSaved(false) }, [2000]);
  }

  return (
    <>
      <StyledMainContent>
        <StyledFlexContainer direction="column">
          <StyledFlexContainer direction="row" justify="space-between">
            <Row align="end" style={{ display: 'flex', margin: '0', justifyContent: 'space-between', width: '100%', padding: '20px 0' }}>
              <StyledFlexContainer direction="column">
                <StyledHeader>
                  <StyledBackButton type="button" onClick={() => handleBack()}>
                    <img src={backArrow} alt="icone de voltar" />
                  </StyledBackButton>
                  {`Catálogo #${id} - Detalhes do Catálogo`}
                </StyledHeader>
                <StyledSubtitle>
                  {`Criado ${catalogInfo?.seller?.name ? `por ${catalogInfo?.seller?.name}` : ''} no dia ${moment(catalogInfo?.createdAt).format('DD/MM/yyyy')} ás ${moment(catalogInfo?.createdAt).format('LT')}`}
                </StyledSubtitle>
              </StyledFlexContainer>
              <StyledRevenuesContainer>
                <StyledRevenues src={Revenues} alt="Background de faturamento" />
                <StyledFlexContainer direction="column">
                  <p className='title'>Faturamento deste catálogo</p>
                  <p className="value">{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', }).format(catalogInfo.revenues || 0)}</p>
                </StyledFlexContainer>
              </StyledRevenuesContainer>
            </Row>
          </StyledFlexContainer>
          <StyledFlexContainer>
            <StyledForms onSubmit={(e) => onFormSubmit(e)}>
              <Row align="end" style={{ display: 'flex', margin: '0', justifyContent: 'space-between', width: '100%', padding: '20px 0' }}>
                <StyledFlexContainer style={{ width: '100%' }} direction="row" wrap="wrap" align="flex-end">
                  <StyledLabel htmlFor='catalog-name'>
                    Título *
                    <StyledInput
                      required
                      placeholder='Título do catálogo'
                      maxLength='100'
                      name="name"
                      id="catalog-name"
                      value={name}
                      onChange={(e) => setName(e.target.value)} />
                  </StyledLabel>
                  <StyledLabel htmlFor='expiresAt'>
                    Data de Expiração
                    <StyledInput
                      type="date"
                      name="name"
                      id="expiresAt"
                      value={expiresAt}
                      onChange={(e) => setExpiresAt(e.target.value)}
                    />
                  </StyledLabel>
                  <StyledButton disabled={isSubmiting} type="submit">
                    {isSubmiting ? (
                      <Loader
                        type="Oval"
                        color={theme.colors.lightGreen}
                        height={20}
                        width={20}
                        timeout={0}
                      />
                    ) : 'ALTERAR DADOS'}
                  </StyledButton>
                </StyledFlexContainer>
              </Row>
            </StyledForms>
          </StyledFlexContainer>
          <StyledCatalogList>
            <StyledTitle>
              Itens do Catálogo
            </StyledTitle>
            {isLoading ? (
              <StyledLoadingContainer>
                <Loader type="Oval" color={theme.colors.lightGreen} height={40} width={40} timeout={0} />
              </StyledLoadingContainer>
            ) : (
              <StyledListItems>
                {products?.map(item => (<CatalogItemCard key={item.id} product={item} />))}
              </StyledListItems>
            )}
          </StyledCatalogList>
          <StyledFlexContainer justify="space-between">
            <Row align="end" style={{ display: 'flex', margin: '0', justifyContent: 'space-between', width: '100%', padding: '20px 0' }}>
              <StyledEditContainer>
                <StyledButton width="200px" onClick={() => setOpen(true)} type="button" margin='0'>
                  EDITAR
                </StyledButton>
              </StyledEditContainer>
              <StyledFlexContainer align="center" justify="center" direction="column" style={{ padding: '10px' }}>
                <StyledCSV>
                  <img src={CSVIcon} alt="Icone de download" />
                  <CSVLink filename={`${name}.csv`} data={CSVProducts}>
                    BAIXAR CATÁLOGO EM CSV
                  </CSVLink>
                </StyledCSV>
                <StyledCSVDescription>
                  <img src={BagIcon} alt="" />
                  <p>Utilize o CSV para exibir seus produtos na sua loja do Instagram.</p>
                </StyledCSVDescription>
              </StyledFlexContainer>
            </Row>
          </StyledFlexContainer>
          {open && (
            <ModalSendContact
              onCloseCallback={() => CloseModalSendContact(false)}
              selectedItems={products}
              associetedCampaign={associetedCampaign}
              setAssocietedCampaign={setAssocietedCampaign}
              setSelectedItems={setProducts}
              handleUpdateCatalog={(e) => onFormSubmit(e)}
            />)}
          {openModal && (<MacroHelpModal onCloseCallback={() => CloseModalMacro(false)} />)}
          <>
            <StyledContactSubtitle>
              Mensagem e Contatos
            </StyledContactSubtitle>
            <StyledBody>
              <StyledFlexContainer justify="flex-start" align="center">
                <Text textAlign="left" fontWeight="bold" fontSize={theme.fontSize._10px} >
                  Texto a ser enviado na mensagem:
                </Text>
                <div className="message-buttons">
                  {copied ? <StyledCheckIcon src={Check} alt="copiado!" /> : (
                    <StyledButtonCopy type="button" onClick={handleCopyMessage}>
                      <StyledCustomIcon src={CopyIcon} alt="icone de copiar" />
                    </StyledButtonCopy>
                  )}
                  {saved ? <StyledCheckIcon src={Check} alt="Salvo!" /> : (
                    <StyledButtonCopy type="button" onClick={handleSaveMessage}>
                      <StyledCustomIcon src={SaveIcon} alt="icone de salvar" />
                    </StyledButtonCopy>
                  )}
                </div>
              </StyledFlexContainer>
              <TextArea height="65px" width="100%" type="text" value={text} onChange={setText} />
              <StyledButtonModal type="button" onClick={() => setOpenModal(true)}>
                <p>Visualizar macros</p>
              </StyledButtonModal>
              <Text textAlign="left" fontWeight="bold" fontSize={theme.fontSize._10px}>
                Lista de envio:
                <Text textAlign="left" fontWeight="bold" fontSize={theme.fontSize._10px} color={theme.colors.lightGreen}>
                  {` ${sendedUsers.length} de ${customers?.length} mensagens enviadas`}
                </Text>
              </Text>
            </StyledBody>
            <StyledBox style={{ maxHeight: '195px' }}>
              {customers &&
                customers.map(customer => {
                  return (
                    <StyledCard key={customer.id} selected={sendedUsers.includes(customer.id) || customer?.received}>
                      {customer.name}
                      <StyledWhatsappButton
                        icon={!sendedUsers.includes(customer.id) && <FaWhatsapp color={theme.colors.white} size={12} />}
                        onClick={() => sendMessage(customer.id, customer.name, customer.phone)}
                        selected={sendedUsers.includes(customer.id)}>
                        {sendedUsers.includes(customer.id) || customer?.received ? 'ENVIADO' : 'ENVIAR'}
                      </StyledWhatsappButton>
                    </StyledCard>
                  );
                })}
            </StyledBox>
            <Footer>
              {!isLoading ? (
                <StyledFooterButtons>
                  <StyledCustomLink backgroundColor={`${theme.colors.lightGreen}`} height="31px" width="220px" target='_blank' href={catalogUrl} >
                    VISUALIZAR CATÁLOGO
                  </StyledCustomLink>
                  <StyledCustomLink backgroundColor="#4A588B" target='_blank' href={`${baseurl}/${portalUrl}/vitrine?sellerId=${sellerId?.id}`} >
                    VISITAR VITRINE
                  </StyledCustomLink>
                </StyledFooterButtons>) : (<br />)}
              <div className='social-media'>
                <FacebookShareButton style={{ display: 'flex' }} quote="título" url={catalogUrl}>
                  <FacebookIcon size="2rem" round />
                </FacebookShareButton>
                <TelegramShareButton style={{ display: 'flex' }} quote="título" url={catalogUrl}>
                  <TelegramIcon size="2rem" round />
                </TelegramShareButton>
                <TwitterShareButton style={{ display: 'flex' }} quote="título" url={catalogUrl}>
                  <TwitterIcon size="2rem" round />
                </TwitterShareButton>
                <WhatsappShareButton style={{ display: 'flex' }} quote="título" url={catalogUrl}>
                  <WhatsappIcon size="2rem" round />
                </WhatsappShareButton>
              </div>
            </Footer>
          </>
          {toastInfo?.message && (
            <Toast type={toastInfo.isError ? 'error' : 'success'} message={toastInfo.message} position="bottom-center" onClose={() => setToastInfo(null)} />
          )}
        </StyledFlexContainer>
      </StyledMainContent>
    </>
  );
}

SpecificSellerCatalog.propTypes = {
};

SpecificSellerCatalog.defaultProps = {
};

export default memo(SpecificSellerCatalog);
