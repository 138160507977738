import ArrowDown from 'assets/icons/arrowDown.svg';
import ArrowUp from 'assets/icons/arrowUp.svg';
import Trash from 'assets/icons/trash-icon.svg';
import Default from 'assets/images/default-product.png';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledFlexContainer } from 'styles/global';
import useWindowDimensions from 'utils/hooks/getScreenSize';

import { Types as CartTypes } from '../../../store/ducks/Checkout';
import {
  StyledMainContainer,
  StyledImage,
  StyledPrice,
  StyledPriceTotal,
  StyledCounterButton,
  StyledCounterDisplay,
  StyledCounterContainer,
  StyledRemoveButton,
  StyledText,
  StyledContainerSmall,
  StyledExternalContainer,
  StyledArrow
} from './styles';

function Product({ info, isEdit, onRemove }) {
  const [counter, setCounter] = useState(1);
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();

  const amount = useSelector(state =>
    state.Checkout.reduce((sumAmount, item) => {
      sumAmount[item.id] = item.amount;
      return sumAmount;
    }, {}),
  );

  const settings = useSelector(state => state.Setting.data);

  useEffect(() => {
    setCounter(amount[info?.id])
  }, [amount]);

  const handleIncrement = () => {
    setCounter(counter + 1);
    dispatch({
      type: CartTypes.UPDATE_AMOUNT,
      id: info?.id,
      amount: amount[info?.id] + 1,
    });
  }

  const handleDecrement = () => {
    if (counter !== 1) {
      setCounter(counter - 1)
    };

    dispatch({
      type: CartTypes.UPDATE_AMOUNT,
      id: info?.id,
      amount: amount[info?.id] - 1,
    });
  }

  return (
    <StyledExternalContainer>

      <StyledMainContainer>
        <StyledFlexContainer align="center" direction="row">
          <StyledImage src={info?.image?.urlImage || Default} alt="imagem do produto" />
          <StyledText direction="column">
            <p>{info?.name}</p>
            <p>{`Tamanho: ${info?.size} - Cor: ${info?.color}`}</p>
            <StyledPrice>{`Preço ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', }).format(info?.price)}`}</StyledPrice>
          </StyledText>
        </StyledFlexContainer>
        <StyledFlexContainer direction="row" align="center" justify="center">
          {windowDimensions.width >= 570 ? (
            <>
              <StyledCounterContainer style={{ color: settings.buttonColor }} >
                {isEdit ? (
                  <>
                    <StyledCounterButton style={{ backgroundColor: settings.buttonColor }} type="button" onClick={handleDecrement}>-</StyledCounterButton>
                    <StyledCounterDisplay>{counter}</StyledCounterDisplay>
                    <StyledCounterButton style={{ backgroundColor: settings.buttonColor }} type="button" onClick={handleIncrement}>+</StyledCounterButton>
                  </>
                ) : (
                  <StyledCounterDisplay>{counter}</StyledCounterDisplay>
                )}
              </StyledCounterContainer>
              <StyledPriceTotal>{`Total ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', }).format(info?.price * counter)}`}</StyledPriceTotal>
            </>
          ) : (
            <StyledContainerSmall>
              <StyledCounterButton style={{ backgroundColor: settings.buttonColor }} type="button" onClick={handleIncrement}>
                <StyledArrow src={ArrowUp} alt="seta" />
              </StyledCounterButton>
              <StyledCounterDisplay>{counter}</StyledCounterDisplay>
              <StyledCounterButton style={{ backgroundColor: settings.buttonColor }} type="button" onClick={handleDecrement}>
                <StyledArrow src={ArrowDown} alt="seta" />
              </StyledCounterButton>
            </StyledContainerSmall>
          )}
          {isEdit && (
            <StyledRemoveButton type="button" onClick={() => onRemove(info.id)}>
              <img src={Trash} alt="ícone da lixeira" />
            </StyledRemoveButton>
          )}
        </StyledFlexContainer>
      </StyledMainContainer>
    </StyledExternalContainer>
  );
}

Product.propTypes = {
  info: PropTypes.shape({
    id: PropTypes.number,
    color: PropTypes.arrayOf(PropTypes.string),
    image: PropTypes.shape({
      urlImage: PropTypes.string,
    }),
    name: PropTypes.string,
    size: PropTypes.arrayOf(PropTypes.string),
    price: PropTypes.number,
  }),
  isEdit: PropTypes.bool,
  onRemove: PropTypes.func
};

Product.defaultProps = {
  info: {
    id: 0,
    image: {
      urlImage: ''
    },
    name: "",
    size: '',
    price: 0
  },
  isEdit: true,
  onRemove: () => { }
};

export default Product;
