import BackOfficeHeader from 'Layout/BackOfficeHeader';
import React from 'react';
import { IoMdHappy, IoIosPaperPlane } from 'react-icons/io';
import {getAppName} from 'utils/app-info';
import avatar from '../../assets/images/avatar.png';
import ContactItem from './ContactItem';
import {
  StyledContainer,
  StyledHeader,
  StyledNumberConversation,
  StyledInfoContact,
  StyledDescripitionContact,
  StyledName,
  StyledNumberContact,
  StyledTitle,
  StyledArticle,
  StyledSection,
  StyledAside,
  StyledAsideFooter,
  StyledInput,
  StyledFooterContent,
} from './styles';


function WhatsChat() {
    return (
      <>
        {/* <BackOfficeHeader
          title={getAppName()}
          path={'home > clientes > chat'}
        /> */}
        <StyledContainer>
          <StyledHeader>
            <StyledNumberConversation>
              <StyledTitle>Conversas</StyledTitle>
              <span>1589 conversas</span>
            </StyledNumberConversation>
            <StyledInfoContact>
              <img src={avatar} alt='' />
              <StyledDescripitionContact>
                <StyledName>Fulano da Silva</StyledName>
                <StyledNumberContact>45 96875-9658</StyledNumberContact>
              </StyledDescripitionContact>
            </StyledInfoContact>
          </StyledHeader>
          <StyledArticle>
            <StyledSection>
              <ContactItem 
                nome='Fulana da silva'
                numero='45 96875-9658'
                contador='4'
              />
              <ContactItem 
                nome='Siciana dos Santos'
                numero='45 96875-9658'
                contador='1'
              />
              <ContactItem 
                nome='João de Tal'
                numero='45 96875-9658'
                contador='4'
              />
              <ContactItem 
                nome='Jean da silva'
                numero='45 96875-9658'
                contador='2'
              />
              <ContactItem 
                nome='Outro João da silva'
                numero='45 96875-9658'
                contador=''
              />
              <ContactItem 
                nome='Geovanna da silva'
                numero='45 96875-9658'
                contador=''
              />
              <ContactItem 
                nome='Matheus da silva'
                numero='45 96875-9658'
                contador=''
              />
              <ContactItem 
                nome='Fulana da silva'
                numero='45 96875-9658'
                contador=''
              />
              <ContactItem 
                nome='Fulana da silva'
                numero='45 96875-9658'
                contador='3'
              />
              <ContactItem 
                nome='Fulana da silva'
                numero='45 96875-9658'
                contador='3'
              />
              <ContactItem 
                nome='Fulana da silva'
                numero='45 96875-9658'
                contador='3'
              />
              <ContactItem 
                nome='Fulana da silva'
                numero='45 96875-9658'
                contador='3'
              />
              <ContactItem 
                nome='Fulana da silva'
                numero='45 96875-9658'
                contador='3'
              />
              <ContactItem 
                nome='Fulana da silva'
                numero='45 96875-9658'
                contador=''
              />
            </StyledSection>
            <StyledAside>
              <StyledAsideFooter>
                <StyledFooterContent>
                  <IoMdHappy size={26} color='#7D94A5' />
                  <StyledInput type='text' />
                  <IoIosPaperPlane size={26} color='#7D94A5' />    
                </StyledFooterContent>
              </StyledAsideFooter>
            </StyledAside>
          </StyledArticle>
        </StyledContainer>
      </>
    );
}

export default WhatsChat;