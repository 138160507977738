import Add from 'assets/icons/add-background-blue.svg';
import Check from 'assets/icons/check-background-green.svg';
import trashIcon from 'assets/icons/trash-icon.svg';
import defaultProduct from 'assets/images/default-product.png';
import Text from 'components/Typografy/Text/index';
import { StyledCatalogItemCard, StyledProductImage, StyledButton, StyledIcon } from 'pages/Customers/CreateCatalogModal/CatalogItemCard/styles';
import PropTypes from 'prop-types';
import React from 'react';
import theme from 'styles/theme';

const CatalogItemCard = ({ onClickRemove, onClickAdd, add, product, selected, disableButton }) => {
  const productState = product;

  const primaryButton = () => {
    return selected ? (
      <StyledButton>
        <img src={Check} alt="selected-icon" />
      </StyledButton>
    ) : (
      <StyledButton onClick={() => onClickAdd(productState)}>
        <img src={Add} alt="add-icon" />
      </StyledButton>
    )
  }

  return (
    <StyledCatalogItemCard className='catalog-card'>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', width: '100%' }}>
        <StyledProductImage src={product?.imageUrl || defaultProduct} alt="imagem do produto" />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
          <h3>{`${product?.name}`}</h3>
          <Text fontSize={theme.fontSize._10px} fontWeight="bold">
            {`A PARTIR DE ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', }).format(product.basePrice || 0)}`}
          </Text>
          {(product?.stock || 0) > 0 ? (
            <Text fontSize={theme.fontSize._10px} fontWeight="bold">
              {`ESTOQUE: ${product?.stock} UN`}
            </Text>) : (
            <Text fontSize={theme.fontSize._10px} color="red" fontWeight="bold">
              {'SEM ESTOQUE'}
            </Text>)
          }
        </div>
        {
          !disableButton && (add ? primaryButton() : onClickRemove && (
            <StyledIcon icon={trashIcon} width="13px" height="14px" color={theme.colors.red} onClick={() => onClickRemove(productState)} style={{ float: 'right', cursor: 'pointer' }} />
          ))
        }
      </div>
    </StyledCatalogItemCard>
  );
};

CatalogItemCard.propTypes = {
  product: PropTypes.object.isRequired,
  onClickRemove: PropTypes.func,
  onClickAdd: PropTypes.func,
  add: PropTypes.bool,
  selected: PropTypes.bool,
  disableButton: PropTypes.bool
};

CatalogItemCard.defaultProps = {
  onClickRemove: null,
  onClickAdd: null,
  add: false,
  selected: false,
  disableButton: false,
};

export default CatalogItemCard;
