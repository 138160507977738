import Discount from 'assets/icons/discount.svg';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';

import Input from '../Input';
import {
  StyledMainContainer,
  StyledTitle,
  StyledNotApplied,
  StyledApplied,
  StyledCirclePhase,
  StyledContainerPhase,
  StyledTextApplied,
  StyledDiscoundContainer,
  StyledRow,
  StyledSum,
  StyledContinueButton,
  StyledLinePhases,
  StyledDivPhases,
  StyledOperationContainer,
  StyledCustomLink
} from './styles';

function OrderSummary({ handleNext, handleShowCase, disabled, disabledDiscount, subTotal, discount, total, freight, promocode, setPromocode, isLoading, textButton, phase }) {
  const [appliedDiscount, setAppliedDiscount] = useState('');
  const settings = useSelector(state => state.Setting.data);

  const verifyDiscount = () => {    
    if(promocode == "")
      setAppliedDiscount('empty');
    else
      setAppliedDiscount('invalid');
  }

  const promocodeValidation = () => {
    if (appliedDiscount === 'valid') {
      return (
        <StyledApplied>
          <img src={Discount} alt="icone de desconto" />
          <StyledTextApplied direction="column">
            <h3>PROMOÇÃO APLICADA</h3>
            <p>Descrição da promoção lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p>
          </StyledTextApplied>
        </StyledApplied>
      );
    } if (appliedDiscount === 'invalid') {
      return (
        <StyledNotApplied>
          Este cupom já expirou ou não é aplicável à sua compra
        </StyledNotApplied>
      );
    }
    if (appliedDiscount === 'empty') {
      return (
        <StyledNotApplied>
          Informe o cupom
        </StyledNotApplied>
      );
    }
    return <></>;
  }

  return (
    <StyledMainContainer>
      <StyledDiscoundContainer>
        <StyledTitle>Resumo do pedido</StyledTitle>
        <Input label="Possui cupom de desconto?" disabled={disabledDiscount} buttonName="VALIDAR" onClick={() => verifyDiscount()} setValue={(e) => setPromocode(e.target.value)} value={promocode} />
        <StyledOperationContainer>
          {promocodeValidation()}
        </StyledOperationContainer>
      </StyledDiscoundContainer>
      <StyledSum>
        <StyledRow st>
          <p>Subtotal</p>
          <p>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', }).format(subTotal)}</p>
        </StyledRow>
        <StyledRow>
          <p>Descontos</p>
          <p>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', }).format(discount)}</p>
        </StyledRow>
        <StyledRow>
          <p>Entrega</p>
          <p>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', }).format(freight)}</p>
        </StyledRow>
      </StyledSum>
      <StyledRow fontWeight="700">
        <p>Total</p>
        <p>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', }).format(total)}</p>
      </StyledRow>
      <StyledCustomLink color={settings.secondaryButtonColor} height="31px" width="220px" target='_blank' onClick={() => handleShowCase()}>
        ESCOLHER MAIS PRODUTOS
      </StyledCustomLink>
      <StyledContinueButton backgroundcolor={settings.buttonColor} disabled={disabled || isLoading} onClick={(e) => handleNext(e)}>
        {isLoading ? (<Loader type="Oval" color="#FFF" height={35} width={35} timeout={0} />) : (textButton)}
      </StyledContinueButton>
      <StyledDivPhases>
        <StyledLinePhases>
          <StyledContainerPhase color={phase >= 0 ? settings.buttonColor : '#BFBFBF'}>
            <StyledCirclePhase color={phase >= 0 ? settings.buttonColor : '#BFBFBF'}>
              1
            </StyledCirclePhase>
            <p>Entrega</p>
          </StyledContainerPhase>
          <StyledContainerPhase color={phase >= 1 ? settings.buttonColor : '#BFBFBF'}>
            <StyledCirclePhase color={phase >= 1 ? settings.buttonColor : '#BFBFBF'}>
              2
            </StyledCirclePhase>
            <p>Identificação</p>
          </StyledContainerPhase>
          <StyledContainerPhase color={phase >= 2 ? settings.buttonColor : '#BFBFBF'}>
            <StyledCirclePhase color={phase >= 2 ? settings.buttonColor : '#BFBFBF'}>
              3
            </StyledCirclePhase>
            <p>Pagamento</p>
          </StyledContainerPhase>
          <StyledContainerPhase color={phase >= 3 ? settings.buttonColor : '#BFBFBF'}>
            <StyledCirclePhase color={phase >= 3 ? settings.buttonColor : '#BFBFBF'}>
              4
            </StyledCirclePhase>
            <p>Conclusão</p>
          </StyledContainerPhase>
        </StyledLinePhases>
      </ StyledDivPhases>
    </StyledMainContainer>
  );
}

OrderSummary.propTypes = {
  handleNext: PropTypes.func,
  disabled: PropTypes.bool,
  subTotal: PropTypes.number,
  discount: PropTypes.number,
  total: PropTypes.number,
  freight: PropTypes.number,
  promocode: PropTypes.string,
  setPromocode: PropTypes.func,
  isLoading: PropTypes.bool,
  textButton: PropTypes.string,
  disabledDiscount: PropTypes.bool,
  handleShowCase: PropTypes.func,
  phase: PropTypes.number
};

OrderSummary.defaultProps = {
  handleNext: () => { },
  disabled: false,
  subTotal: 0,
  discount: 0,
  total: 0,
  freight: 0,
  promocode: '',
  setPromocode: () => { },
  isLoading: false,
  textButton: 'Prosseguir',
  disabledDiscount: PropTypes.bool,
  handleShowCase: () => { }
};

export default OrderSummary;
